/* Variables */
:root {
    /* Primary-Gold */
    --p50: #fbf7ee; 
    --p75: #f0dcb9;
    --p100: #eace9c;
    --p200: #e1b872;
    --p300: #dbaa55;
    --p400: #99773b;
    --p500: #866834;

    /* Secondary-Dark Gold */
    --sd50: #f0ede8;
    --sd75: #c1b59f;
    --sd100: #a79678;
    --sd200: #81693e;
    --sd300: #674a16;
    --sd400: #48340f;
    --sd500: #3f2d0d;

    /* Tertiary-Dope Black */
    --t50: #eaeaeb;
    --t75: #a9aaac;
    --t100: #858689;
    --t200: #515257;
    --t300: #2d2f34;
    --t400: #1f2124;
    --t500: #1b1d20;

    /* Neutral */
    --n0: #ffffff;
    --n10: #fafafa;
    --n20: #f5f5f5;
    --n30: #ececec;
    --n40: #e0e0e0;
    --n50: #c4c4c4;
    --n60: #b5b5b5;
    --n70: #a9a9a9;
    --n80: #9b9b9b;
    --n90: #8d8d8d;
    --n100: #7e7e7e;
    --n200: #707070;
    --n300: #626262;
    --n400: #565656;
    --n500: #484848;
    --n600: #3c3c3c;
    --n700: #2b2b2b;
    --n800: #1d1d1d;
    --n900: #111111;

    /* Warning */
    --w50: #ffe6e6;
    --w75: #ff9696;
    --w100: #ff6b6b;
    --w200: #ff2b2b;
    --w300: #ff0000;
    --w400: #b30000;
    --w500: #9c0000;

    /* Successful */
    --s50: #ebf7ed;
    --s75: #ade0b5;
    --s100: #8bd396;
    --s200: #59c069;
    --s300: #37b34a;
    --s400: #277d34;
    --s500: #226d2d;

    /* Shine-Gold */
    --sg100: linear-gradient(90deg, #E6C86A 0%, #DBAA55 100%);
    --sg200: linear-gradient(90deg, #FFD386 0%, #9D6A12 100%);

    /* Highlight */
    --h100: #ff6d50;

    /* Price Variation */
    --v100: #37c6e5;
    --v200: #b57bff;
    --v300: #ea6fb9;
    --v400: #bf8123;


    /**
     * Color Code with opacity 
     * https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
     */
    --n900o60: #11111199; 
}

/* Background Color */
.bg-p50 { background-color: var(--p50) !important; }
.bg-p75 { background-color: var(--p75) !important; }
.bg-p100 { background-color: var(--p100) !important; }
.bg-p200 { background-color: var(--p200) !important; }
.bg-p300 { background-color: var(--p300) !important; }
.bg-p400 { background-color: var(--p400) !important; }
.bg-p500 { background-color: var(--p500) !important; }
.bg-sd50 { background-color: var(--sd50) !important; }
.bg-sd75 { background-color: var(--sd75) !important; }
.bg-sd100 { background-color: var(--sd100) !important; }
.bg-sd200 { background-color: var(--sd200) !important; }
.bg-sd300 { background-color: var(--sd300) !important; }
.bg-sd400 { background-color: var(--sd400) !important; }
.bg-sd500 { background-color: var(--sd500) !important; }
.bg-t50 { background-color: var(--t50) !important; }
.bg-t75 { background-color: var(--t75) !important; }
.bg-t100 { background-color: var(--t100) !important; }
.bg-t200 { background-color: var(--t200) !important; }
.bg-t300 { background-color: var(--t300) !important; }
.bg-t400 { background-color: var(--t400) !important; }
.bg-t500 { background-color: var(--t500) !important; }
.bg-n0 { background-color: var(--n0) !important; }
.bg-n10 { background-color: var(--n10) !important; }
.bg-n20 { background-color: var(--n20) !important; }
.bg-n30 { background-color: var(--n30) !important; }
.bg-n40 { background-color: var(--n40) !important; }
.bg-n50 { background-color: var(--n50) !important; }
.bg-n60 { background-color: var(--n60) !important; }
.bg-n70 { background-color: var(--n70) !important; }
.bg-n80 { background-color: var(--n80) !important; }
.bg-n90 { background-color: var(--n90) !important; }
.bg-n100 { background-color: var(--n100) !important; }
.bg-n200 { background-color: var(--n200) !important; }
.bg-n300 { background-color: var(--n300) !important; }
.bg-n400 { background-color: var(--n400) !important; }
.bg-n500 { background-color: var(--n500) !important; }
.bg-n600 { background-color: var(--n600) !important; }
.bg-n700 { background-color: var(--n700) !important; }
.bg-n800 { background-color: var(--n800) !important; }
.bg-n900 { background-color: var(--n900) !important; }
.bg-w50 { background-color: var(--w50) !important; }
.bg-w75 { background-color: var(--w75) !important; }
.bg-w100 { background-color: var(--w100) !important; }
.bg-w200 { background-color: var(--w200) !important; }
.bg-w300 { background-color: var(--w300) !important; }
.bg-w400 { background-color: var(--w400) !important; }
.bg-w500 { background-color: var(--w500) !important; }
.bg-s50 { background-color: var(--s50) !important; }
.bg-s75 { background-color: var(--s75) !important; }
.bg-s100 { background-color: var(--s100) !important; }
.bg-s200 { background-color: var(--s200) !important; }
.bg-s300 { background-color: var(--s300) !important; }
.bg-s400 { background-color: var(--s400) !important; }
.bg-s500 { background-color: var(--s500) !important; }
.bg-sg100 { background: var(--sg100) !important; }
.bg-sg200 { background: var(--sg200) !important; }
.bg-h100 { background-color: var(--h100) !important; }
.bg-v100 { background-color: var(--v100) !important; }
.bg-v200 { background-color: var(--v200) !important; }
.bg-v300 { background-color: var(--v300) !important; }
.bg-v400 { background-color: var(--v400) !important; }

/* Text Color */
.text-p50 { color: var(--p50) !important; }
.text-p75 { color: var(--p75) !important; }
.text-p100 { color: var(--p100) !important; }
.text-p200 { color: var(--p200) !important; }
.text-p300 { color: var(--p300) !important; }
.text-p400 { color: var(--p400) !important; }
.text-p500 { color: var(--p500) !important; }
.text-sd50 { color: var(--sd50) !important; }
.text-sd75 { color: var(--sd75) !important; }
.text-sd100 { color: var(--sd100) !important; }
.text-sd200 { color: var(--sd200) !important; }
.text-sd300 { color: var(--sd300) !important; }
.text-sd400 { color: var(--sd400) !important; }
.text-sd500 { color: var(--sd500) !important; }
.text-t50 { color: var(--t50) !important; }
.text-t75 { color: var(--t75) !important; }
.text-t100 { color: var(--t100) !important; }
.text-t200 { color: var(--t200) !important; }
.text-t300 { color: var(--t300) !important; }
.text-t400 { color: var(--t400) !important; }
.text-t500 { color: var(--t500) !important; }
.text-n0 { color: var(--n0) !important; }
.text-n10 { color: var(--n10) !important; }
.text-n20 { color: var(--n20) !important; }
.text-n30 { color: var(--n30) !important; }
.text-n40 { color: var(--n40) !important; }
.text-n50 { color: var(--n50) !important; }
.text-n60 { color: var(--n60) !important; }
.text-n70 { color: var(--n70) !important; }
.text-n80 { color: var(--n80) !important; }
.text-n90 { color: var(--n90) !important; }
.text-n100 { color: var(--n100) !important; }
.text-n200 { color: var(--n200) !important; }
.text-n300 { color: var(--n300) !important; }
.text-n400 { color: var(--n400) !important; }
.text-n500 { color: var(--n500) !important; }
.text-n600 { color: var(--n600) !important; }
.text-n700 { color: var(--n700) !important; }
.text-n800 { color: var(--n800) !important; }
.text-n900 { color: var(--n900) !important; }
.text-w50 { color: var(--w50) !important; }
.text-w75 { color: var(--w75) !important; }
.text-w100 { color: var(--w100) !important; }
.text-w200 { color: var(--w200) !important; }
.text-w300 { color: var(--w300) !important; }
.text-w400 { color: var(--w400) !important; }
.text-w500 { color: var(--w500) !important; }
.text-s50 { color: var(--s50) !important; }
.text-s75 { color: var(--s75) !important; }
.text-s100 { color: var(--s100) !important; }
.text-s200 { color: var(--s200) !important; }
.text-s300 { color: var(--s300) !important; }
.text-s400 { color: var(--s400) !important; }
.text-s500 { color: var(--s500) !important; }
.text-sg100 { color: var(--sg100) !important; }
.text-sg200 { color: var(--sg200) !important; }
.text-h100 { color: var(--h100) !important; }
.text-v100 { color: var(--v100) !important; }
.text-v200 { color: var(--v200) !important; }
.text-v300 { color: var(--v300) !important; }
.text-v400 { color: var(--v400) !important; }

/* Border Color */ 
.border-p50 { border-color: var(--p50) !important; }
.border-p75 { border-color: var(--p75) !important; }
.border-p100 { border-color: var(--p100) !important; }
.border-p200 { border-color: var(--p200) !important; }
.border-p300 { border-color: var(--p300) !important; }
.border-p400 { border-color: var(--p400) !important; }
.border-p500 { border-color: var(--p500) !important; }
.border-sd50 { border-color: var(--sd50) !important; }
.border-sd75 { border-color: var(--sd75) !important; }
.border-sd100 { border-color: var(--sd100) !important; }
.border-sd200 { border-color: var(--sd200) !important; }
.border-sd300 { border-color: var(--sd300) !important; }
.border-sd400 { border-color: var(--sd400) !important; }
.border-sd500 { border-color: var(--sd500) !important; }
.border-t50 { border-color: var(--t50) !important; }
.border-t75 { border-color: var(--t75) !important; }
.border-t100 { border-color: var(--t100) !important; }
.border-t200 { border-color: var(--t200) !important; }
.border-t300 { border-color: var(--t300) !important; }
.border-t400 { border-color: var(--t400) !important; }
.border-t500 { border-color: var(--t500) !important; }
.border-n0 { border-color: var(--n0) !important; }
.border-n10 { border-color: var(--n10) !important; }
.border-n20 { border-color: var(--n20) !important; }
.border-n30 { border-color: var(--n30) !important; }
.border-n40 { border-color: var(--n40) !important; }
.border-n50 { border-color: var(--n50) !important; }
.border-n60 { border-color: var(--n60) !important; }
.border-n70 { border-color: var(--n70) !important; }
.border-n80 { border-color: var(--n80) !important; }
.border-n90 { border-color: var(--n90) !important; }
.border-n100 { border-color: var(--n100) !important; }
.border-n200 { border-color: var(--n200) !important; }
.border-n300 { border-color: var(--n300) !important; }
.border-n400 { border-color: var(--n400) !important; }
.border-n500 { border-color: var(--n500) !important; }
.border-n600 { border-color: var(--n600) !important; }
.border-n700 { border-color: var(--n700) !important; }
.border-n800 { border-color: var(--n800) !important; }
.border-n900 { border-color: var(--n900) !important; }
.border-w50 { border-color: var(--w50) !important; }
.border-w75 { border-color: var(--w75) !important; }
.border-w100 { border-color: var(--w100) !important; }
.border-w200 { border-color: var(--w200) !important; }
.border-w300 { border-color: var(--w300) !important; }
.border-w400 { border-color: var(--w400) !important; }
.border-w500 { border-color: var(--w500) !important; }
.border-s50 { border-color: var(--s50) !important; }
.border-s75 { border-color: var(--s75) !important; }
.border-s100 { border-color: var(--s100) !important; }
.border-s200 { border-color: var(--s200) !important; }
.border-s300 { border-color: var(--s300) !important; }
.border-s400 { border-color: var(--s400) !important; }
.border-s500 { border-color: var(--s500) !important; }
.border-sg100 { border-color: var(--sg100) !important; }
.border-sg200 { border-color: var(--sg200) !important; }
.border-h100 { border-color: var(--h100) !important; }
.border-v100 { border-color: var(--v100) !important; }
.border-v200 { border-color: var(--v200) !important; }
.border-v300 { border-color: var(--v300) !important; }
.border-v400 { border-color: var(--v400) !important; }