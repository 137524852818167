/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 900;
  src: url("assets/fonts/Poppins-Black.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 800;
  src: url("assets/fonts/Poppins-ExtraBold.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 700;
  src: url("assets/fonts/Poppins-Bold.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 600;
  src: url("assets/fonts/Poppins-SemiBold.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 500;
  src: url("assets/fonts/Poppins-Medium.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 400;
  src: url("assets/fonts/Poppins-Regular.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 300;
  src: url("assets/fonts/Poppins-Light.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 200;
  src: url("assets/fonts/Poppins-ExtraLight.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 100;
  src: url("assets/fonts/Poppins-Thin.ttf") format("TrueType");
}


/* PrimeNG */
/*@import "assets/themes/lara/lara-light/blue/theme.scss";*/
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Editor */
@import "../node_modules/quill/dist/quill.snow.css";
@import "assets/css/custom.css";

html,
body {
  font-size: 12px;
  font-family: "Poppins";
  margin: 0;
  padding: 0;
  color: var(--surface-900);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  html,
  body {
    font-size: 14px;
  }

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1160px;
  }
}

@media (min-width: 1600px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1300px;
  }
}

.max-h-38rem {
  max-height: 38rem !important;
}

/**
 * START - Button Custom Primary
 */
p-button.p-disabled:has(.btn-custom-primary) {
  opacity: 1;
}

.p-button.btn-custom-primary {
  background: var(--sg100);
  border: none;
  color: black;
  transition: none;
  font-weight: 600;

  &:disabled {
    background: var(--n70);
    opacity: 1;
    transition: none;
  }
}

.p-button.btn-custom-primary:enabled:hover,
.p-button.btn-custom-primary:enabled:focus {
  background: var(--sg100);
  color: black;
}
/**
 * END - Button Custom Primary
 */

/**
 * START - Button Custom Primary Outlined
 */
p-button.p-disabled:has(.btn-custom-primary-outlined) {
  opacity: 1;
}

.p-button.btn-custom-primary-outlined {
  background-color: transparent;
  color: black;
  border: 1px solid var(--p300);

  &:disabled {
    opacity: 1;
    border-color: var(--n70);
    transition: none;
  }
}

.p-button.btn-custom-primary-outlined:enabled:hover,
.p-button.btn-custom-primary-outlined:enabled:focus {
  background: transparent;
  color: black;
  border: 1px solid var(--p300);
}
/**
 * END - Button Custom Primary Outlined
 */

.p-button.btn-allow-disable:disabled {
  background-color: var(--n50);
  border-color: var(--n50);
  color: black;
  opacity: 1;
}

.p-inputgroup.inputgroup_rounded {

  input:first-child,
  .p-inputgroup-addon:first-child {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  button:last-child,
  input:last-child,
  .p-inputwrapper:last-child>.p-component>.p-inputtext {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
}

.p-dropdown.dropdown_primary {
  border-color: var(--primary-color);

  .p-dropdown-label {
    color: var(--primary-color);
  }

  .p-dropdown-trigger {
    color: var(--primary-color);
  }
}

.p-overlaypanel.overlay_full {
  .p-overlaypanel-content {
    padding: 0;
  }
}

.p-overlaypanel.op-price-info {
  .p-overlaypanel-content {
    padding: 0.75rem;
  }

  .table-price-info {
    table {
      tr {
        td {
          padding: 0;
          border: none;
        }

        td:first-child > span {
          padding-left: 0.5rem;
        }

        td:last-child > span {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }
    }
  }
}

/**
 * START - Custom Paginator
 */
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--surface-900);
  color: var(--surface-100);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus {
  box-shadow: none;
}
/**
 * END - Custom Paginator
 */

.shadow_primary_hover:hover {
  box-shadow: 0px 3px 5px var(--primary-color), 0px 0px 2px var(--primary-color),
    0px 1px 4px var(--primary-color) !important;
}

.display-1-line {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.display-2-line {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.display-3-line {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

// used by select platform voucher sidebar
.p-sidebar.p-sidebar-right {
  width: 795px;
}

.p-dialog-content {
  color: var(--surface-900);

  .p-inputtext {
    color: var(--surface-900);
  }
}

// dynamic dialog in checkout page select address
.checkout-address-dialog {
  .p-dialog-header {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;

    .p-dialog-title {
      width: 100%;
      text-align: center;
      font-size: 1.75rem;
      font-weight: 600;
      padding-left: 28px;
    }
  }

  .p-dialog-content {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
}

// dynamic dialog for add/edit/delete address
.dialog-content-address {
  .p-dialog-content {
    padding: 1rem 0 1rem 0 !important;
    overflow-y: hidden !important;
    border-radius: 1.5rem !important;
    height: calc(80vh + 2rem);
  }

  .p-dialog-content>.ng-star-inserted>div {
    padding-left: 2rem;
    padding-right: 2rem;
    overflow-y: auto;
    height: 80vh;
  }
}

// dynamic dialog for qm verification 
.dialog-content-qm-verification {
  .p-dialog-content {
    padding: 1rem 2rem !important;
    overflow-y: hidden !important;
    border-radius: 1.5rem !important;
    max-height: 80vh;
  }
}

.dialog-content-confirmation {
  .p-dialog-content {
    padding-bottom: 0;
    border-radius: 1.5rem !important;
    width: 450px;
  }
}

.dialog-content-mobile {
  .p-dialog-content {
    padding-bottom: 0;
    border-radius: 1rem !important;
    width: 80vw;
  }
}

.dialog-content-select-reason {
  .p-dialog-header {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
    border-bottom: 1px solid var(--surface-300);
    padding-bottom: 1rem;

    .p-dialog-title {
      width: 100%;
      padding-left: calc(28px + 2rem);
      padding-right: 2rem;
      color: var(--n900);
      text-align: center;
    }
  }

  .p-dialog-content {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
    padding-bottom: 0;
  }
}

/**
 * START - comments-related class, used in return and review page
 */
.comments-content[contenteditable]:empty::before {
  content: attr(data-placeholder);
  color: var(--surface-400);
  font-weight: 400;
  cursor: text;
}

.comments-content {
  border: 1px solid var(--n50);
  border-bottom: none;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  outline: 0px solid transparent;
  padding: 0.5rem 1rem 0 1rem;
  background-color: white;
  min-height: calc(100px + 0.5rem);
  word-break: break-all;
  font-weight: 500;
}

.comments-footer {
  border: 1px solid var(--n50);
  border-top: none;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  color: var(--surface-400);
  background-color: white;
  padding: 0 1rem 0.5rem 1rem;
}
/**
 * END - comments-related class, used in return and review page
 */

/**
 * START - radiobutton styling
 */
.p-radiobutton {
  .p-radiobutton-box:not(.p-highlight) {
    border-color: var(--n900);
    border-width: 1px;
  }

  .p-radiobutton-box.p-highlight {
    .p-radiobutton-icon {
      background-color: var(--primary-color);
      border: 2px solid white;
      width: 14px;
      height: 14px;
    }
  }

  .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }
}
/**
 * END - radiobutton styling
 */

.p-toast.toast_dark {
  opacity: 1;

  .p-toast-message {
    border-radius: 1.5rem;

    .p-toast-message-content {
      background-color: var(--n300);
      color: white;
    }

    .p-toast-icon-close {
      display: none;
    }
  }
}

.p-timeline.timeline-tracking-details {
  .p-timeline-event {
    .p-timeline-event-opposite {
      flex: 0 1 auto; // flex-initial
      width: 9rem;
    }

    .p-timeline-event-separator {
      .timeline-custom-marker {
        background-color: var(--surface-400);
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
      }

      .timeline-custom-marker.event-highlight {
        background: var(--sg100);
      }

      .timeline-horizontal-marker {
        background-color: var(--surface-400);
        width: 2px;
        height: 0.75rem;
        margin-left: calc(0.75rem - 1px);
      }

      .timeline-horizontal-marker+.p-timeline-event-connector {
        margin-left: calc(0.75rem - 1px);
      }

      .timeline-horizontal-marker.event-highlight {
        background: var(--sg100);
      }

      .p-timeline-event-connector {
        background-color: var(--surface-400);
      }
    }
  }

  .p-timeline-event:has(+ .p-timeline-event > .p-timeline-event-separator > .event-highlight)>.p-timeline-event-separator>.p-timeline-event-connector {
    background: var(--sg100);
  }

  .p-timeline-event:has(+ .p-timeline-event > .p-timeline-event-content > div.hide-section)>.p-timeline-event-separator>.p-timeline-event-connector {
    background-color: var(--surface-400);
  }

  .p-timeline-event:has(+ .p-timeline-event > .p-timeline-event-separator > .event-highlight):has(+ .p-timeline-event > .p-timeline-event-content > div.hide-section)>.p-timeline-event-separator:has(> .timeline-custom-marker.next-step-completed)>.p-timeline-event-connector {
    background: var(--sg100);
  }

  .p-timeline-event:has(> .p-timeline-event-content > div.hide-section) {
    display: none;
  }

  .p-timeline-event-content {
    margin-bottom: 1rem;

    .timeline-content-horizontal-parent {
      margin-left: calc((0.75rem + 4px) * -1); // multiply -1 to get negative value
      margin-right: 1rem;
      margin-top: 2.5px;
    }

    .timeline-content-horizontal-line {
      background-color: var(--surface-400);
      width: 1.5rem;
      height: 2px;
    }

    .timeline-content-ball {
      background-color: var(--surface-400);
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }
  }
}

/**
 * START - used by buttons, quantity and status in Profile -> My Orders
 */
.w-order-card-col {
  width: 130px;
}
/**
 * END - used by buttons, quantity and status in Profile -> My Orders
 */

/**
  * START - black checkbox styling
  */
.p-checkbox-black.p-checkbox {
  .p-checkbox-box {
    border-color: var(--surface-900);
  }

  .p-checkbox-box.p-highlight {
    border-color: var(--surface-900);
    background: var(--surface-900);
  }
}

.p-checkbox-black.p-checkbox:not(.p-checkbox-disabled) {
  .p-checkbox-box:hover {
    border-color: var(--surface-900);
  }

  .p-checkbox-box.p-highlight:hover {
    border-color: var(--surface-900);
    background: var(--surface-900);
    color: var(--surface-0);
  }

  .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0rem var(--surface-0);
    border-color: var(--surface-900);
  }
}
/**
  * END - black checkbox styling
  */

/**
  * START - black radio button styling
  */
.p-radiobutton-black.p-radiobutton {
  .p-radiobutton-box.p-highlight {
    border-color: var(--surface-900);
    background: var(--surface-900);

    .p-radiobutton-icon {
      background-color: var(--surface-900);
    }
  }
}

.p-radiobutton-black.p-radiobutton:not(.p-radiobutton-disabled) {
  .p-radiobutton-box:hover {
    border-color: var(--surface-900);
  }

  .p-radiobutton-box.p-highlight:hover {
    border-color: var(--surface-900);
    background: var(--surface-900);
    color: var(--surface-0);
  }

  .p-radiobutton-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0rem var(--surface-0);
    border-color: var(--surface-900);
  }
}
/**
  * END - black radio button styling
  */

.myqm-field {
  border: none;
  border-bottom: 1px solid var(--n100);

  input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    border: none;
    box-shadow: none !important;
  }

  input.input-clearable {
    padding-right: calc(15px + 0.5rem); // for input field that have clear input button
  }

  input::placeholder {
    font-weight: 400;
  }

  .div-clear-input {
    margin-left: calc(-1 * (15px + 0.5rem)); // Multiply -1 to get negative value

    .btn-clear-input {
      background: transparent !important;
    }
  }
}

.custom-float-label {
  font-size: 12px;
}

.p-float-label label {
  left: 0;
}

.p-float-label>.ng-invalid.ng-dirty+label {
  color: var(--text-color-secondary);
}

/* Add webkit autofill selector to fix float label bug */
.p-float-label .p-inputwrapper:has(input:-webkit-autofill)~label,
.p-float-label textarea:-webkit-autofill~label,
.p-float-label input:-webkit-autofill~label,
.p-float-label input:focus~label,
.p-float-label input.p-filled~label {
  font-size: 12px;
  top: -0.5rem;
}

button.p-button.font-normal>span.p-button-label {
  font-weight: 400;
}

.bg-transparent {
  background: transparent;
}

.text-transparent {
  color: transparent;
}

/**
 * START - custom loader
 */
/* 
 * https://css-loaders.com/spinner/ 
 *
 * HTML Component: <div class="custom-loader"></div>
 */
.custom-loader {
  width: 20px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(0 0 0/100%) 0) 50%/8% 100%,
    linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0) 50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}

.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.custom-loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes l23 {
  100% {
    transform: rotate(1turn)
  }
}
/**
 * END - custom loader
 */

/**
 * START - Carousel Items
 */
.p-carousel {
  position: relative;

  .p-carousel-content {

    .p-carousel-prev,
    .p-carousel-next {
      position: absolute;
      z-index: 1;
      background-color: var(--surface-50);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03),
        0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
    }

    .p-carousel-prev:enabled:hover,
    .p-carousel-next:enabled:hover {
      background-color: var(--surface-200);
    }

    .p-carousel-next.p-disabled,
    .p-carousel-prev.p-disabled {
      display: none;
    }

    .p-carousel-next {
      right: -1.5rem;
    }

    .p-carousel-prev {
      left: -1.5rem;
    }
  }
}
/**
 * END - Carousel Items
 */

/*@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

router-outlet + * {
  display: block;
  animation: fade .5s;
}*/


/*
* DISABLE PRIMENG P-IMAGE ROTATE BUTTONS 
*/
.p-image-toolbar {
  .p-image-action:nth-child(1),
  .p-image-action:nth-child(2) {
    display: none;
  }  
}

.op-gsa .p-overlaypanel-content,
.op-cash .p-overlaypanel-content {
  color: var(--n900);

  div.commodity-gsa-info:not(:last-child) {
    padding-bottom: 0.5rem;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: var(--n50);
  }

  div.commodity-gsa-info:not(:first-child) {
    margin-top: 0.5rem;
  }
}

.op-payment-method .p-overlaypanel-content {
  color: var(--n900);

  div.qm-gold-info-divider {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: var(--n50);
  }
}

.sidebar-mobile-qm-payment-method {
  div, button {
    color: var(--n900);
  }

  .p-sidebar-header {
    padding-top: 1.25rem;
    padding-bottom: 0;
  }

  .p-sidebar-content {
    padding-left: 0;
    padding-right: 0;
  }

  .p-sidebar-footer {
    padding: 0;
  }

  div.commodity-gsa-info:not(:last-child) {
    padding-bottom: 0.5rem;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: var(--n50);
  }

  div.commodity-gsa-info:not(:first-child) {
    margin-top: 0.5rem;
  }

  div.qm-gold-info-divider {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: var(--n50);
  }
}